<template>
    <main id="liste" v-cloak>
        <HeaderTab 
            :title="$t('menu.setting.notif_management')" 
            :swiper_tabs="swiper_tabs" 
            :swiper_action="changeTab" 
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <DateRange
                                :start.sync="start_date"
                                :end.sync="end_date"
                                @submit="actesDate(start_date.toDateInputValue(), end_date.toDateInputValue())"
                            />

                            <CustomTable
								v-if="actes_groupes.length"
								ref="table"
                                id_table="horse_actes_clientarea"
                                primaryKey="actes_id"
                                :items="local_actes"
								:selected_ids.sync="selected_ids"
                                :busy.sync="table_busy"
								:hrefsRoutes="config_table_hrefs"
								:externSlotColumns="['notes.type_fonts']"
                            >
								<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
									<div 
										v-if="data.notes && Object.keys(data.notes.type_fonts).length > 0" 
										class="previewColIdentifier"
									>
										<span 
											v-for="(font, key) in data.notes.type_fonts"
											:key="key"
										>
											<font-awesome-icon :icon="font.font" /> {{ font.occurences }}
										</span>
									</div>
								</template>
							</CustomTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<ModelChooser
			ref="modeles_modal"
			@submit="notifySubmit"
		/>
    </main>
</template>


<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js";
    import Navigation from "@/mixins/Navigation.js";


	export default {
		name: "acteListeNotification",
		mixins: [ActeMixin, Navigation],
		data () {
			return {
				swiper_tabs: [{
                        label: 'acte.tous',
                        href : 'acteListe',
                        active: true,
                        name: ''
                    }
                ],
				actual_tab: {},
                filter: '',
                actes_groupes: [],
                show_main_vue: true,
                actes: [],
                local_actes: [],
                interacted_actes: [],
				selected_ids: [],

                /* Configuration du tableau : */
				table_busy: true,
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
                
                events_tab: {
                    'TableAction::notifyActs': this.notifySubmit
				},
				config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                },
			}
		},
		created() {
            // On init les données de la vue
            this.init_component()
		},
		methods: {
            async init_component() {
                this.actes_groupes = await this.getActesGroupes(true)

                this.actes_groupes.forEach(acte_groupe => {
                    this.swiper_tabs.push({
                        label: 'acte.'+acte_groupe.actesgroupe_label.toLowerCase(),
                        active: false,
                        name: acte_groupe.actesgroupe_label
                    })
				})

				await this.actesDate(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())

                this.actual_tab = this.swiper_tabs[0]
                this.table_busy = false
			},
			
			async actesDate(start_date, end_date) {
                this.table_busy = true
                this.actes = await this.getActesHistoryCa(start_date, end_date)
				this.actes.forEach(acte => {
					acte.tiers_formatted = acte.tiers.map(t => t.tiers_rs).join(', ')
				});
                this.local_actes = this.actes
                this.table_busy = false
            },

			async changeTab(tab) {
                this.actual_tab.active = false
				this.actual_tab = tab
                tab.active = true
                if(tab.name === "")
                    this.local_actes = this.actes
                else
                    this.local_actes = this.actes.filter(acte => acte.actegroupe.actesgroupe_label == tab.name)
			},
			async notifySubmit() {
				const selectedIds = this.selected_ids
				await this.notifyClient(selectedIds, 0)
                .then(() => {
                    this.successToast('toast.notif_sent')
                })
				.catch(() => {
					this.failureToast("toast.info_save_failed")
				})
			}
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
            ModelChooser: () => import('@/components/Model/ModelChooser')
		}
	}
</script>